const posKeys = [
  'Aod',
  'CccEmptyCart',
  'OpenCartCouponHelpernew',
  'CartCouponHelpernew',
  'CartGetCoupons',
  'TradeQuickship',
  'Discountlistshowlimitexceededtip',
  'Cartnoteligibleforcoupons',
  'Add',
]
const newPosKeys = [
  'CartAddress',
  'BannerDistrictimage',
  'OrderPageCouponTest',
  'UnderPrice',
  'LowpriceUser',
  'ReturnCouponShow',
  'ShowPromotion',
  // 'Cartallactivitytype',
  'Cartcouponadd',
  'recSwitch',
  'addJson',
  'addnewhot',
  'addnewhotJson',
  'EstimatedDetailSwitch',
  'CouponAddToCartToast',
  'CouponHelperAddMultiple',
  'Cartshowcoupon',
  'SheinFindSimilar',
  'Couponaddall',
  'brTaxhint',
  'ShopLineRetention',
  'UserBehaviorTips',
  'CartGoodsLabel',
  'TradeEventAtmosphere',
  'CartActivityColor',
  'TaxSwitch',
  'CartNotCheckedEstimate',
  'CartrowEstimatedCountdown',
  'DiscountInfoSwitch',
  'Discountlogo',
  'EstimatedPrice',
  'cartSelect',
  'orderCouponNewUser',
  'CartBottomCopy',
  'PayinCart',
  'CartQuickshipVisual',
  'IncentiveInform',
  'cartPromotionsPriority',
  'EmptyCartUnpaidOrder',
  'QSinformation',
  'CartShippingTopSwitch',
  'bottomNumberStyle',
  'evoluSHEINShow',
  'Cartstoreinrow',
  'CancelledorderTip',
  'storeiconchange',
  'Cartrefund',
  'cartPromotions',
  'LowestPriceDays',
  'paypalCartSwitch',
  'CartaddGoodsdetailSwitch',
  'defaultlocalsize',
  'cartRightsFuns',
  'CartNewCouponlist',
  'cartPriceStyle',
  'outofstocktips',
  'cartRecommendGuide',
  'promotionCouponMix',
  'CartOOSGoodsPosition',
  'PaymentSafetyExperiment',
  'PaymentSecurity',
  'cartRightsFreereturn',
  'cartGoodsAddPromos',
  'NewCartrowVision',
  'CartrowWishlistIcon',
  'cartShowStoreCode',
  'cartShowBrand',
  'cartStoreFreeGift',
  'cartRightsFreereturn',
  'cartRightsLabel',
  'CartOrderSFSVisual',
  'ActivityOver',
  'Cartcredit',
  'cartDiscountStyle',
  'MallQuickOperation',
]

export {
  posKeys,
  newPosKeys
}
